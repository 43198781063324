import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { resolvePath, useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { pages } from 'pages';
import { useSendAccessCode, useVerifyAccessCode } from 'api/account';
import { toast } from 'shared/components/Toast/Toast';
import { Header } from 'modules/auth/components/Header/Header';
import {
  VerificationPinForm,
  VerificationPinFormInput,
} from 'modules/auth/components/VerificationPinForm/VerificationPinForm';
import { AuthBox, AuthWrapper } from 'modules/auth/Auth.styles';
import useUser from 'hooks/useUser';
import getActiveAccount from 'contexts/getActiveAccount';
import { datadogRum } from '@datadog/browser-rum';
import { getDashboardRedirectPath } from 'modules/auth/Auth.utils';
import { HttpStatusCode } from 'axios';

const VERIFICATION_TIME_SECONDS = 10 * 60; // 10 minutes

export function Verification() {
  const [searchParams] = useSearchParams();

  const [email] = useState(searchParams.get('email') || '');
  const [timeLeft, setTimeLeft] = useState(VERIFICATION_TIME_SECONDS);

  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const { setUserResult } = useUser();
  const isMFA = location.state?.isMFA || undefined;

  useEffect(() => {
    if (!email) {
      navigate(`/${pages.REGISTRATION}`);
    }
  }, [email, navigate]);

  useEffect(() => {
    let timerId: NodeJS.Timeout;

    if (timeLeft > 0) {
      timerId = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    }

    return () => clearInterval(timerId);
  }, [timeLeft]);

  const { mutate: sendAccessCode } = useSendAccessCode({
    onSuccess: () => {
      setTimeLeft(VERIFICATION_TIME_SECONDS);
    },
    onError: ({ status }) => {
      const errorMessage =
        status === HttpStatusCode.TooManyRequests
          ? t('toast.tooManyAttempts')
          : t('toast.somethingWentWrong');
      toast({
        type: 'error',
        title: t('toast.error'),
        message: errorMessage,
        theme,
      });
    },
  });

  const {
    mutate: verifyAccessCode,
    error: verifyAccessCodeError,
    isLoading,
  } = useVerifyAccessCode({
    onSuccess: (response, variables) => {
      if (isMFA) {
        const { user } = response.data;
        setUserResult({
          user,
          loading: false,
          selectedAccount: getActiveAccount(user),
        });
        datadogRum.setUser({
          id: user.id,
          email: user.email,
          name: `${user.first_name} ${user.last_name}`,
          role: user.role,
        });
        const { hasDashboardAccess, redirectPath } = getDashboardRedirectPath(
          user,
          user.accounts?.[0],
        );
        if (hasDashboardAccess) {
          navigate(resolvePath(pages.OVERVIEW, `/${pages.DASHBOARD}`));
        } else navigate(redirectPath);
      } else {
        const params = new URLSearchParams({
          email: variables.email,
          access_code: variables.access_code,
        });

        navigate(`/${pages.PASSWORD}?${params.toString()}`);
      }
    },
    onError: ({ response }) => {
      const status = response?.status;
      const unCaughtError = status !== 400 && status !== 422 && status !== 429;

      if (status === 429) {
        navigate(`/${pages.TOO_MANY_ATTEMPTS}`);
      }

      if (unCaughtError) {
        toast({
          type: 'error',
          title: t('toast.error'),
          message: t('toast.somethingWentWrong'),
          theme,
        });
      }
    },
  });

  const handleSubmitPinForm = ({ pin }: VerificationPinFormInput) => {
    verifyAccessCode({
      email,
      access_code: pin,
      is_MFA: isMFA,
    });
  };

  const handleResendPin = () => {
    sendAccessCode({ email });
  };

  let pinErrorMessage: string | undefined;
  if (verifyAccessCodeError?.response?.status === 400) {
    pinErrorMessage = t('verification.wrongCode');
  }

  if (verifyAccessCodeError?.response?.status === 422) {
    pinErrorMessage = t('verification.expiredCode');
  }

  return (
    <AuthWrapper>
      <AuthBox>
        <Header
          title={isMFA ? t('verification.verifyYour') : t('verification.checkYour')}
          titleBold={isMFA ? t('verification.account') : t('verification.email')}
          showLogo
        />
        <VerificationPinForm
          email={email.toLowerCase()}
          timeLeftSeconds={timeLeft}
          verifying={isLoading}
          isMFA={Boolean(isMFA)}
          pinError={pinErrorMessage}
          onSubmitForm={handleSubmitPinForm}
          onResendPin={handleResendPin}
        />
      </AuthBox>
    </AuthWrapper>
  );
}

export default Verification;
